// Must add everything inside .rsOne-wordpress-container to avoid css issues in other sections
.rsOne-wordpress-container {
  --wp--preset--color--black: #000000;
  --wp--preset--color--cyan-bluish-gray: #abb8c3;
  --wp--preset--color--white: #ffffff;
  --wp--preset--color--pale-pink: #f78da7;
  --wp--preset--color--vivid-red: #cf2e2e;
  --wp--preset--color--luminous-vivid-orange: #ff6900;
  --wp--preset--color--luminous-vivid-amber: #fcb900;
  --wp--preset--color--light-green-cyan: #7bdcb5;
  --wp--preset--color--vivid-green-cyan: #00d084;
  --wp--preset--color--pale-cyan-blue: #8ed1fc;
  --wp--preset--color--vivid-cyan-blue: #0693e3;
  --wp--preset--color--vivid-purple: #9b51e0;
  --wp--preset--color--dark-gray: #28303d;
  --wp--preset--color--gray: #39414d;
  --wp--preset--color--green: #d1e4dd;
  --wp--preset--color--blue: #d1dfe4;
  --wp--preset--color--purple: #d1d1e4;
  --wp--preset--color--red: #e4d1d1;
  --wp--preset--color--orange: #e4dad1;
  --wp--preset--color--yellow: #eeeadd;
  --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(
    135deg,
    rgba(6, 147, 227, 1) 0%,
    rgb(155, 81, 224) 100%
  );
  --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(
    135deg,
    rgb(122, 220, 180) 0%,
    rgb(0, 208, 130) 100%
  );
  --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(
    135deg,
    rgba(252, 185, 0, 1) 0%,
    rgba(255, 105, 0, 1) 100%
  );
  --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(
    135deg,
    rgba(255, 105, 0, 1) 0%,
    rgb(207, 46, 46) 100%
  );
  --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(
    135deg,
    rgb(238, 238, 238) 0%,
    rgb(169, 184, 195) 100%
  );
  --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(
    135deg,
    rgb(74, 234, 220) 0%,
    rgb(151, 120, 209) 20%,
    rgb(207, 42, 186) 40%,
    rgb(238, 44, 130) 60%,
    rgb(251, 105, 98) 80%,
    rgb(254, 248, 76) 100%
  );
  --wp--preset--gradient--blush-light-purple: linear-gradient(135deg, rgb(255, 206, 236) 0%, rgb(152, 150, 240) 100%);
  --wp--preset--gradient--blush-bordeaux: linear-gradient(
    135deg,
    rgb(254, 205, 165) 0%,
    rgb(254, 45, 45) 50%,
    rgb(107, 0, 62) 100%
  );
  --wp--preset--gradient--luminous-dusk: linear-gradient(
    135deg,
    rgb(255, 203, 112) 0%,
    rgb(199, 81, 192) 50%,
    rgb(65, 88, 208) 100%
  );
  --wp--preset--gradient--pale-ocean: linear-gradient(
    135deg,
    rgb(255, 245, 203) 0%,
    rgb(182, 227, 212) 50%,
    rgb(51, 167, 181) 100%
  );
  --wp--preset--gradient--electric-grass: linear-gradient(135deg, rgb(202, 248, 128) 0%, rgb(113, 206, 126) 100%);
  --wp--preset--gradient--midnight: linear-gradient(135deg, rgb(2, 3, 129) 0%, rgb(40, 116, 252) 100%);
  --wp--preset--gradient--purple-to-yellow: linear-gradient(160deg, #d1d1e4 0%, #eeeadd 100%);
  --wp--preset--gradient--yellow-to-purple: linear-gradient(160deg, #eeeadd 0%, #d1d1e4 100%);
  --wp--preset--gradient--green-to-yellow: linear-gradient(160deg, #d1e4dd 0%, #eeeadd 100%);
  --wp--preset--gradient--yellow-to-green: linear-gradient(160deg, #eeeadd 0%, #d1e4dd 100%);
  --wp--preset--gradient--red-to-yellow: linear-gradient(160deg, #e4d1d1 0%, #eeeadd 100%);
  --wp--preset--gradient--yellow-to-red: linear-gradient(160deg, #eeeadd 0%, #e4d1d1 100%);
  --wp--preset--gradient--purple-to-red: linear-gradient(160deg, #d1d1e4 0%, #e4d1d1 100%);
  --wp--preset--gradient--red-to-purple: linear-gradient(160deg, #e4d1d1 0%, #d1d1e4 100%);
  --wp--preset--duotone--dark-grayscale: url('#wp-duotone-dark-grayscale');
  --wp--preset--duotone--grayscale: url('#wp-duotone-grayscale');
  --wp--preset--duotone--purple-yellow: url('#wp-duotone-purple-yellow');
  --wp--preset--duotone--blue-red: url('#wp-duotone-blue-red');
  --wp--preset--duotone--midnight: url('#wp-duotone-midnight');
  --wp--preset--duotone--magenta-yellow: url('#wp-duotone-magenta-yellow');
  --wp--preset--duotone--purple-green: url('#wp-duotone-purple-green');
  --wp--preset--duotone--blue-orange: url('#wp-duotone-blue-orange');
  --wp--preset--font-size--small: 18px;
  --wp--preset--font-size--medium: 20px;
  --wp--preset--font-size--large: 24px;
  --wp--preset--font-size--x-large: 42px;
  --wp--preset--font-size--extra-small: 16px;
  --wp--preset--font-size--normal: 20px;
  --wp--preset--font-size--extra-large: 40px;
  --wp--preset--font-size--huge: 96px;
  --wp--preset--font-size--gigantic: 144px;
}
.has-black-color {
  color: var(--wp--preset--color--black) !important;
}
.has-cyan-bluish-gray-color {
  color: var(--wp--preset--color--cyan-bluish-gray) !important;
}
.has-white-color {
  color: var(--wp--preset--color--white) !important;
}
.has-pale-pink-color {
  color: var(--wp--preset--color--pale-pink) !important;
}
.has-vivid-red-color {
  color: var(--wp--preset--color--vivid-red) !important;
}
.has-luminous-vivid-orange-color {
  color: var(--wp--preset--color--luminous-vivid-orange) !important;
}
.has-luminous-vivid-amber-color {
  color: var(--wp--preset--color--luminous-vivid-amber) !important;
}
.has-light-green-cyan-color {
  color: var(--wp--preset--color--light-green-cyan) !important;
}
.has-vivid-green-cyan-color {
  color: var(--wp--preset--color--vivid-green-cyan) !important;
}
.has-pale-cyan-blue-color {
  color: var(--wp--preset--color--pale-cyan-blue) !important;
}
.has-vivid-cyan-blue-color {
  color: var(--wp--preset--color--vivid-cyan-blue) !important;
}
.has-vivid-purple-color {
  color: var(--wp--preset--color--vivid-purple) !important;
}
.has-dark-gray-color {
  color: var(--wp--preset--color--dark-gray) !important;
}
.has-gray-color {
  color: var(--wp--preset--color--gray) !important;
}
.has-green-color {
  color: var(--wp--preset--color--green) !important;
}
.has-blue-color {
  color: var(--wp--preset--color--blue) !important;
}
.has-purple-color {
  color: var(--wp--preset--color--purple) !important;
}
.has-red-color {
  color: var(--wp--preset--color--red) !important;
}
.has-orange-color {
  color: var(--wp--preset--color--orange) !important;
}
.has-yellow-color {
  color: var(--wp--preset--color--yellow) !important;
}
.has-black-background-color {
  background-color: var(--wp--preset--color--black) !important;
}
.has-cyan-bluish-gray-background-color {
  background-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}
.has-white-background-color {
  background-color: var(--wp--preset--color--white) !important;
}
.has-pale-pink-background-color {
  background-color: var(--wp--preset--color--pale-pink) !important;
}
.has-vivid-red-background-color {
  background-color: var(--wp--preset--color--vivid-red) !important;
}
.has-luminous-vivid-orange-background-color {
  background-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}
.has-luminous-vivid-amber-background-color {
  background-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}
.has-light-green-cyan-background-color {
  background-color: var(--wp--preset--color--light-green-cyan) !important;
}
.has-vivid-green-cyan-background-color {
  background-color: var(--wp--preset--color--vivid-green-cyan) !important;
}
.has-pale-cyan-blue-background-color {
  background-color: var(--wp--preset--color--pale-cyan-blue) !important;
}
.has-vivid-cyan-blue-background-color {
  background-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}
.has-vivid-purple-background-color {
  background-color: var(--wp--preset--color--vivid-purple) !important;
}
.has-dark-gray-background-color {
  background-color: var(--wp--preset--color--dark-gray) !important;
}
.has-gray-background-color {
  background-color: var(--wp--preset--color--gray) !important;
}
.has-green-background-color {
  background-color: var(--wp--preset--color--green) !important;
}
.has-blue-background-color {
  background-color: var(--wp--preset--color--blue) !important;
}
.has-purple-background-color {
  background-color: var(--wp--preset--color--purple) !important;
}
.has-red-background-color {
  background-color: var(--wp--preset--color--red) !important;
}
.has-orange-background-color {
  background-color: var(--wp--preset--color--orange) !important;
}
.has-yellow-background-color {
  background-color: var(--wp--preset--color--yellow) !important;
}
.has-black-border-color {
  border-color: var(--wp--preset--color--black) !important;
}
.has-cyan-bluish-gray-border-color {
  border-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}
.has-white-border-color {
  border-color: var(--wp--preset--color--white) !important;
}
.has-pale-pink-border-color {
  border-color: var(--wp--preset--color--pale-pink) !important;
}
.has-vivid-red-border-color {
  border-color: var(--wp--preset--color--vivid-red) !important;
}
.has-luminous-vivid-orange-border-color {
  border-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}
.has-luminous-vivid-amber-border-color {
  border-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}
.has-light-green-cyan-border-color {
  border-color: var(--wp--preset--color--light-green-cyan) !important;
}
.has-vivid-green-cyan-border-color {
  border-color: var(--wp--preset--color--vivid-green-cyan) !important;
}
.has-pale-cyan-blue-border-color {
  border-color: var(--wp--preset--color--pale-cyan-blue) !important;
}
.has-vivid-cyan-blue-border-color {
  border-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}
.has-vivid-purple-border-color {
  border-color: var(--wp--preset--color--vivid-purple) !important;
}
.has-dark-gray-border-color {
  border-color: var(--wp--preset--color--dark-gray) !important;
}
.has-gray-border-color {
  border-color: var(--wp--preset--color--gray) !important;
}
.has-green-border-color {
  border-color: var(--wp--preset--color--green) !important;
}
.has-blue-border-color {
  border-color: var(--wp--preset--color--blue) !important;
}
.has-purple-border-color {
  border-color: var(--wp--preset--color--purple) !important;
}
.has-red-border-color {
  border-color: var(--wp--preset--color--red) !important;
}
.has-orange-border-color {
  border-color: var(--wp--preset--color--orange) !important;
}
.has-yellow-border-color {
  border-color: var(--wp--preset--color--yellow) !important;
}
.has-vivid-cyan-blue-to-vivid-purple-gradient-background {
  background: var(--wp--preset--gradient--vivid-cyan-blue-to-vivid-purple) !important;
}
.has-light-green-cyan-to-vivid-green-cyan-gradient-background {
  background: var(--wp--preset--gradient--light-green-cyan-to-vivid-green-cyan) !important;
}
.has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
  background: var(--wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange) !important;
}
.has-luminous-vivid-orange-to-vivid-red-gradient-background {
  background: var(--wp--preset--gradient--luminous-vivid-orange-to-vivid-red) !important;
}
.has-very-light-gray-to-cyan-bluish-gray-gradient-background {
  background: var(--wp--preset--gradient--very-light-gray-to-cyan-bluish-gray) !important;
}
.has-cool-to-warm-spectrum-gradient-background {
  background: var(--wp--preset--gradient--cool-to-warm-spectrum) !important;
}
.has-blush-light-purple-gradient-background {
  background: var(--wp--preset--gradient--blush-light-purple) !important;
}
.has-blush-bordeaux-gradient-background {
  background: var(--wp--preset--gradient--blush-bordeaux) !important;
}
.has-luminous-dusk-gradient-background {
  background: var(--wp--preset--gradient--luminous-dusk) !important;
}
.has-pale-ocean-gradient-background {
  background: var(--wp--preset--gradient--pale-ocean) !important;
}
.has-electric-grass-gradient-background {
  background: var(--wp--preset--gradient--electric-grass) !important;
}
.has-midnight-gradient-background {
  background: var(--wp--preset--gradient--midnight) !important;
}
.has-purple-to-yellow-gradient-background {
  background: var(--wp--preset--gradient--purple-to-yellow) !important;
}
.has-yellow-to-purple-gradient-background {
  background: var(--wp--preset--gradient--yellow-to-purple) !important;
}
.has-green-to-yellow-gradient-background {
  background: var(--wp--preset--gradient--green-to-yellow) !important;
}
.has-yellow-to-green-gradient-background {
  background: var(--wp--preset--gradient--yellow-to-green) !important;
}
.has-red-to-yellow-gradient-background {
  background: var(--wp--preset--gradient--red-to-yellow) !important;
}
.has-yellow-to-red-gradient-background {
  background: var(--wp--preset--gradient--yellow-to-red) !important;
}
.has-purple-to-red-gradient-background {
  background: var(--wp--preset--gradient--purple-to-red) !important;
}
.has-red-to-purple-gradient-background {
  background: var(--wp--preset--gradient--red-to-purple) !important;
}
.has-small-font-size {
  font-size: var(--wp--preset--font-size--small) !important;
}
.has-medium-font-size {
  font-size: var(--wp--preset--font-size--medium) !important;
}
.has-large-font-size {
  font-size: var(--wp--preset--font-size--large) !important;
}
.has-x-large-font-size {
  font-size: var(--wp--preset--font-size--x-large) !important;
}
.has-extra-small-font-size {
  font-size: var(--wp--preset--font-size--extra-small) !important;
}
.has-normal-font-size {
  font-size: var(--wp--preset--font-size--normal) !important;
}
.has-extra-large-font-size {
  font-size: var(--wp--preset--font-size--extra-large) !important;
}
.has-huge-font-size {
  font-size: var(--wp--preset--font-size--huge) !important;
}
.has-gigantic-font-size {
  font-size: var(--wp--preset--font-size--gigantic) !important;
}
